import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

export class Updates extends Component {
  static displayName = Updates.name;

  constructor (props) {
    super(props);
    this.state = {
        content: '*loading...*'
    }
}

  getPageData = () => {
    return fetch('https://raw.githubusercontent.com/MatthewECreighton/Updates/master/random-drop-update.md?2021051603')
    .then(response => response.text())
    .then(data => {
      return data;
    })
    .catch(error => console.error(error))
  }

  componentDidMount() {
    this.getPageData().then(response => this.setState({
      content: response
    }));
  }

  render () {

    return (
      <div className="updates">
        <h2>Updates for Random Drop</h2>
        <hr />
        <p>Updates for <strong>Random Drop</strong> will be posted here.</p>
        <hr />
        <ReactMarkdown>
            {this.state.content}
        </ReactMarkdown>
      </div>
    );
  }
}