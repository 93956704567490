import React, { Component } from 'react'

export class SimpleMapSelector extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            
        };
    }

    handleClick = (index) => {
        this.props.onIndexChange(index);
    }

    handleKeyPress = (e, index) => {
        switch(e.key)
        {
            case " ":
                this.handleClick(index);
                break;
            case "Enter":
                this.handleClick(index);
                break;
            default:
                break;
        }
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
       
    }

    render() {
        let myOptions = this.props.values.map((value, index) => {
            return <option
                key={index} 
                value={index}
            >
                { value }
            </option>
        });

        return (
            <div className='simpleMapSelector'>
                <label htmlFor="mapChosen" className='mapLabel'>Map:</label>
                <select 
                    name="mapChosen" 
                    id="mapChosen"
                    value={this.props.currentIndex} 
                    onChange={(e) => this.handleClick(e.target.value)}
                    className="mapSelect"
                >
                    { myOptions }
                </select>
            </div>
        )
    }
}