import React, { Component } from 'react'
import CookieConsent, {
    getCookieConsentValue,
  } from "react-cookie-consent";
import { MapContainer } from './MapContainer';
import TagManager from 'react-gtm-module'
import { Route } from 'react-router';
import { Updates } from './Updates';
import Cookies from 'universal-cookie';

export class Wrapper extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            darkMode: true,
            mapToLoad: 'Caldera',
        };

        this.cookies = new Cookies();
    }

    handleAcceptCookie = async () => {
        try {
            if (!window.location.hostname.includes('localhost')) {
                TagManager.initialize({gtmId: 'GTM-TM8MHZX'});
            } else {
                console.log('Running on localhost. Google analytics is disabled.');
            }
        } catch (error) {
            console.log(error);
        }
    };

    loadPreferences = () => {
        let darkModeEnabled = true;
        let mapToLoad = 'Caldera';

        try {
            let darkModeEnabledCookie = this.cookies.get('darkModeEnabled');
            if (darkModeEnabledCookie === 'true') {
                darkModeEnabled = true;
            } else {
                darkModeEnabled = false;
            }

        } catch (error) {
            let year = new Date().getFullYear();
            this.cookies.set('darkModeEnabled', darkModeEnabled, { expires: new Date(year + 5, 1, 1) });
        }

        if (darkModeEnabled) {
            this.setState({
                darkMode: darkModeEnabled
            }, () => {
                this.setDarkModeInDom();
            })
        }

        let mapCookieName = 'mapToLoad';

        try {
            let mapToLoadCookie = this.cookies.get(mapCookieName);

            if (mapToLoadCookie === 'Rebirth') {
                mapToLoad = 'Rebirth'
            } 
            else if (mapToLoadCookie === 'Caldera') {
                mapToLoad = 'Caldera';
            } else if (mapToLoadCookie === "Fortune's Keep") {
                mapToLoad = "Fortune's Keep";
            // uncomment following lines to to support new map in cookie list
            // } else if (mapToLoadCookie === 'Verdansk') {
            //     mapToLoad = 'Verdansk';
            } else {
                mapToLoad = 'Caldera';
            }

        } catch (error) {
            let year = new Date().getFullYear();
            this.cookies.set(mapCookieName, mapToLoad, { expires: new Date(year + 5, 1, 1) });
        }

        if (mapToLoad) {
            this.setState({
                mapToLoad: mapToLoad
            });
        }
    }

    saveMapPreference = (name) => {
        if (getCookieConsentValue() === "true") {
            let year = new Date().getFullYear();

            this.cookies.set('mapToLoad', name, { expires: new Date(year + 5, 1, 1) });
            this.setState({
                mapToLoad: name,
            });
        }
    }

    updateStoredPreviousDrops = (mapName, drops) => {
        if (getCookieConsentValue() === "true") {
            let year = new Date().getFullYear();
            let cookieName = `${mapName}_drops`;

            this.cookies.set(cookieName, JSON.stringify(drops), { expires: new Date(year + 5, 1, 1) });
        }
    }

    getStoredPreviousDrops = (mapName) => {
        try {
            let cookieName = `${mapName}_drops`;
            let value = this.cookies.get(cookieName);

            if (value) {
                return value;
            } else {
                return [];
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    setDarkModeInDom = () => {
        let bahdy = document.querySelector('body');
        if (this.state.darkMode) {
            bahdy.classList.add('dark');
        } else {
            bahdy.classList.remove('dark');
        }
    }

    toggleDarkMode = () => {
        this.setState({
            darkMode: !this.state.darkMode,
        }, () => {
            this.setDarkModeInDom();

            if (getCookieConsentValue() === "true") {
                let year = new Date().getFullYear();
                this.cookies.set('darkModeEnabled', this.state.darkMode, { expires: new Date(year + 5, 1, 1) });
            }
        })
    }

    componentDidMount() {      
        const hasConsent = getCookieConsentValue();
        if (hasConsent === "true") {
            this.handleAcceptCookie();
            this.loadPreferences();            
        }

        this.setDarkModeInDom();
    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div>
                <h1>Random Drop Has Shut Down</h1>
                <p>This project is no longer being maintained.</p>
            </div>
            // <div className="wrapper">
            //     <h1>Warzone Random Drop Generator</h1>
            //     <p>Select Caldera or Fortune's Keep from the dropdown menu then press the "Generate Drop Spot" button below the map.</p>
            //     <>
            //         <Route exact path='/' render={(props) => 
            //             <MapContainer 
            //                 toggleDarkMode={this.toggleDarkMode} 
            //                 darkMode={this.state.darkMode}
            //                 mapToLoad={this.state.mapToLoad}
            //                 saveMapPreference={this.saveMapPreference}
            //                 updateStoredPreviousDrops={this.updateStoredPreviousDrops}
            //                 getStoredPreviousDrops={this.getStoredPreviousDrops}
            //                 {...props} 
            //             />
            //         } />
            //         <Route path='/updates' component={Updates} />
            //     </>
            //     <div className="bottom">
            //         created by <a href="https://twitter.com/MattECre">Matthew Creighton</a> | { window.location.href.includes('updates') ? <><a href="/">home</a> | updates </> : <><>home</> | <a href="/updates">updates</a></>  }
            //     </div>
            //     <CookieConsent 
            //         enableDeclineButton={false}
            //         onAccept={this.handleAcceptCookie}
            //     >
            //         This website uses cookies for analytics tracking and enhancing user experience
            //     </CookieConsent>
            // </div>
        )
    }
}
