import React, { Component } from 'react'
import { SimpleMapSelector } from './SimpleMapSelector';

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            x: 0,
            y: 0,
            canvasWidth: 1,     // width in pixels
            canvasHeight: 1,    // height in pixels
            showingSpot: false,
            preventRepeats: true,
            previousDrops: [],
            currentMap: null,
            darkModeOn: false,
            currentIndex: 0
        };

        this.maps = [
            new Map(
                "Caldera", 
                "caldera",
                8,
                10,
                ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                .0,
                .0,
                .09,
                [
                    {x: 0, y: 0},
                    {x: 1, y: 0},
                    {x: 2, y: 0},
                    {x: 3, y: 0},
                    {x: 6, y: 0},
                    {x: 7, y: 0},
                    {x: 0, y: 1},
                    {x: 1, y: 1},
                    {x: 0, y: 2},
                    {x: 0, y: 7},
                    {x: 0, y: 8},
                    {x: 1, y: 8},
                    {x: 0, y: 9},
                    {x: 1, y: 9},
                    {x: 2, y: 9},
                    {x: 3, y: 9},
                    {x: 4, y: 9},
                    {x: 7, y: 9},
                ], 
                15
            ),
            new Map(
                "Fortune's Keep", 
                "fortunesKeep",
                8,
                5,
                ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
                [2, 3, 4, 5, 6],
                .0,
                .0,
                .1,
                [
                    {x: 0, y: 0}, 
                    {x: 1, y: 0}, 
                    {x: 2, y: 0}, 
                    {x: 0, y: 1}, 
                    {x: 6, y: 0}, 
                    {x: 7, y: 0}, 
                ],
                14
            ),
            new Map(
                "Rebirth", 
                "rebirth",
                5,
                5,
                ['D', 'E', 'F', 'G', 'H'],
                [2, 3, 4, 5, 6],
                .14,
                .125,
                .1220,
                [
                    {x: 0, y: 0}, 
                    {x: 1, y: 0}, 
                    {x: 2, y: 0}, 
                    {x: 0, y: 1}, 
                    {x: 0, y: 2}, 
                    {x: 4, y: 3}, 
                    {x: 4, y: 4}
                ],
                11
            )
        ]
    }

    clearCoordinates() {
        this.setState({
            showingSpot: false,
        }, () => {
            let unitToHighlight = document.getElementById(this.state.x + "," + this.state.y);
            if (unitToHighlight) {
                unitToHighlight.classList.remove('highlighted');
            }
        });
    }

    deleteHistory() {
        this.clearLastDrops();
        this.clearCoordinates();
        this.clearLastDrops();
        this.clearHistoryCookiesForMap();

    }

    clearLastDrops() {
        this.setState({
            previousDrops: [],
            x: 0,
            y: 0,
            showingSpot: false,
        })
    }

    clearHistoryCookiesForMap() {
        this.props.updateStoredPreviousDrops(
            this.state.currentMap.name, 
            []
        );
    }

    generateNewCoordinates() {
        let newRandomX = this.getRandomInt(this.state.currentMap.maxX);
        let newRandomY = this.getRandomInt(this.state.currentMap.maxY);
        let newDrop = {x : newRandomX, y: newRandomY};
        let repeat = this.state.previousDrops.find(drop => drop.x === newRandomX && drop.y === newRandomY);
        let onExclusionList = this.state.currentMap.excludedUnits.find(drop => drop.x === newRandomX && drop.y === newRandomY);

        let updateState = (newDrops) => {
            this.setState({
                x: newRandomX,
                y: newRandomY,
                showingSpot: true,
                previousDrops: newDrops,
            }, () => {
                let unitToHighlight = document.getElementById(this.state.x + "," + this.state.y);
                unitToHighlight.classList.add('highlighted');
                this.props.updateStoredPreviousDrops(
                    this.state.currentMap.name, 
                    newDrops
                );
            });
        }

        if (!onExclusionList) {
            if (this.state.preventRepeats) {
                if (!repeat) {
                    let newDrops = this.state.previousDrops;
                    
                    if (this.state.previousDrops.length >= this.state.currentMap.repeatMax) {
                        newDrops = newDrops.filter((d, i) => {
                            if (i === 0) {
                                return false;
                            } else {
                                return true;
                            }
                        });
        
                        newDrops.push(newDrop);
                    } else {
                        newDrops.push(newDrop);
                    }
        
                    updateState(newDrops);
                } else {
                    this.generateNewCoordinates();
                }
            } else {
                updateState([]);
            }
        } else {
            this.generateNewCoordinates();
        }

    }

    // getRandomInt(numberOfSlots): get int from 0 to (numberOfSlots - 1)
    getRandomInt(numberOfSlots) {
        return Math.floor(Math.random() * Math.floor(numberOfSlots));
    }

    getGridCoordinates = (x, y) => {
        return this.state.currentMap.xAxis[x] + (this.state.currentMap.yAxis[y].toString());
    }

    toggleRepeats = () => {
        this.setState({
            preventRepeats: !this.state.preventRepeats,
        });
    }

    updateDimensions = () => {
        this.setState({ 
            windowWidth: window.innerWidth, 
            windowHeight: window.innerHeight 
        }, () => {
            this.setInitialSquareSize();
        });
    }

    setInitialSquareSize = () => {
        let square = document.querySelector('.square');

        if (square) {
            this.setState({
                canvasWidth: square.clientWidth,
                canvasHeight: square.clientHeight,
            }, () => {
                
            });
        }
    }

    changeMap = (index) => {
        let mapToUpdateTo = this.maps[index]

        if (mapToUpdateTo.name === this.state.currentMap.name) {
            return;
        }

        this.setState({
            currentMap: mapToUpdateTo,
        }, () => {
            this.props.saveMapPreference(mapToUpdateTo.name);
            this.clearCoordinates();
        });
    }

    grid = () => {
        let rows = [];
        if (this.state.currentMap) {
            
            for (let yIndex = 0; yIndex < this.state.currentMap.maxY; yIndex++) {
                for (let xIndex = 0; xIndex < this.state.currentMap.maxX; xIndex++) {
                    
                    let coordinates = this.getGridCoordinates(xIndex, yIndex);
                    let isPreviousUnit = this.state.previousDrops.find(drop => drop.x === xIndex && drop.y === yIndex);
                    let isUndroppable = this.state.currentMap.excludedUnits.find(drop => drop.x === xIndex && drop.y === yIndex);

                    let createSpanClassName = (isXAxis) => {
                        let theClass = "axis";
                        if (xIndex === 0 && isXAxis) {
                            theClass += " y";
                        }

                        if (yIndex === 0 && !isXAxis) {
                            theClass += " x";
                        }

                        return theClass;
                    }
                    let unit = 
                        <div className={`unit ${isPreviousUnit ? 'bad' : ''} ${ isUndroppable ? 'badbad' : ''}`} key={`${xIndex},${yIndex}`} id={`${xIndex},${yIndex}`}>
                            {xIndex === 0 ? 
                                <span className={createSpanClassName(true)}>
                                    {xIndex === 0 ? coordinates[1] : null}
                                </span> : null}
                            {yIndex === 0 ? 
                                <span className={createSpanClassName(false)}>
                                    {yIndex === 0 ? coordinates[0] : null}
                                </span> : null}
                            <span className="unitLabel">{coordinates}</span>
                        </div>;
                    rows.push(unit);
                }
            }           
        }

        return <div className="myCanvas">{rows}</div>
    }

    getMapIndex = (name) => {
        let mapNames = this.maps.map(m => m.name);
        return mapNames.indexOf(name);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.mapToLoad !== this.props.mapToLoad) {
            try {
                let mapNames = this.maps.map(m => m.name);
                let desiredIndex = mapNames.indexOf(this.props.mapToLoad);
                let loadedPreviousDrops = this.props.getStoredPreviousDrops(this.props.mapToLoad);

                this.changeMap(desiredIndex);
                this.setState({
                    currentIndex: desiredIndex,
                    previousDrops: loadedPreviousDrops,
                });
            } catch (error) {
                console.error(error);
            }
        }
    }

    componentDidMount() {
        let mapNames = this.maps.map(m => m.name);
        let desiredIndex = mapNames.indexOf(this.props.mapToLoad);
        let loadedPreviousDrops = this.props.getStoredPreviousDrops(this.props.mapToLoad);

        this.setState({
            currentMap: this.maps[desiredIndex],
            currentIndex: desiredIndex,
            previousDrops: loadedPreviousDrops,
        }, () => {
            window.addEventListener('resize', this.updateDimensions);
            this.setInitialSquareSize();
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        let squareClass = "square " + (this.state.currentMap ? this.state.currentMap.className : "");

        return (
            <div className="black-background">
                <SimpleMapSelector 
                    currentIndex={this.state.currentIndex}
                    values={this.maps.map(m => m.name)}
                    onIndexChange={this.changeMap}
                />
                <div className={squareClass}>
                    <this.grid />
                </div>
                {this.state.showingSpot === false ? 
                    <button onClick={() => this.generateNewCoordinates()} className="generate">Generate Drop Spot</button>
                    : <button onClick={() => this.clearCoordinates()} className="clear">Clear Drop Spot</button>
                }
                <button onClick={() => this.deleteHistory()} className="clear all">Delete History</button>
                <div className="center-my-children">
                    <div>
                        <input 
                            checked={this.state.preventRepeats}
                            type="checkbox" 
                            onChange={() => {this.toggleRepeats()}} 
                            id="checkboxOfCoolness"
                        />
                        <label htmlFor="checkboxOfCoolness">
                            prevent repeat drops ({this.state.currentMap ? this.state.currentMap.repeatMax : 0})
                        </label>
                    </div>
                    <div>
                        <input 
                            checked={this.props.darkMode}
                            type="checkbox" 
                            onChange={() => {this.props.toggleDarkMode()}} 
                            id="darkModeCheckboxOfCoolness"
                        />
                        <label htmlFor="darkModeCheckboxOfCoolness">
                            dark mode
                        </label>                    
                    </div>
                </div>
            </div>
        );
    }
}

class Map {
    constructor (
        name, 
        className,
        maxX,
        maxY,
        xAxis,
        yAxis,
        startXPercentage,
        startYPercentage,
        unitPercentage,
        excludedUnits,
        repeatMax,
    ) {
        this.name = name;
        this.className = className;
        this.maxX = maxX;
        this.maxY = maxY;
        this.xAxis = xAxis;
        this.yAxis = yAxis;
        this.startXPercentage = startXPercentage;
        this.startYPercentage = startYPercentage;
        this.unitPercentage = unitPercentage;
        this.excludedUnits = excludedUnits;
        this.repeatMax = repeatMax;
    }

    name = "";
    className = "";
    maxX = 0;
    maxY = 0;
    xAxis = [];
    yAxis = [];
    startXPercentage = 0.0;
    startYPercentage = 0.0;
    unitPercentage = 0.0;
    excludedUnits = [];
    repeatMax = 0;
}